import React, { Component } from 'react';

import Search from './Search';
import Breadcrumb from "./../../header/Breadcrumb";
import Pagination from './Pagination';
import ProductList from './ProductList';
import ProductDiscounted from './ProductDiscounted';
import CategoryMenu from './CategoryMenu';
import Token from "./../../utils/Token";
import Discount from "./../../utils/Discount";

class Catalog extends Component {
    topRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            isSearch: false,
            groups: [],
            products: [],
            loaded: false,
            searchLoader: true,
            countPages: 0,
            currentPage: 0,
            countOnPage: 30,
            error: null,
            remainsMax: false,
            notFound: false,
            search: {
                year: 0,
                value: "",
                model: "",
                manufacture: "",
            },
        };
    }

    isJSON(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    async componentDidMount() {
        const response = await fetch('/api/user/remains', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Token': (new Token()).get()
            }
        });

        this.setState({ remainsMax: response.ok });

        await this.loadAllProducts();

        const queryParams = new URLSearchParams(window.location.search);

        const searchParam = queryParams.get('search');

        if (searchParam) {
            if (this.isJSON(searchParam)) {
                const baseUrl = window.location.origin + '/catalog';
                window.history.replaceState({}, document.title, baseUrl);
        
                const values = JSON.parse(decodeURIComponent(searchParam));

                const valuesSearch = values.value;
        
                if (valuesSearch && (valuesSearch.year || valuesSearch.value || valuesSearch.model || valuesSearch.manufacture)) {
                    this.setState({
                        search: valuesSearch,
                        isSearch: true,
                    }, async () => {
                        await this.handleEdit(valuesSearch);
                    });
                } else {
                    if ((values == "Infinity") && (searchParam.length > 0)) {
                        const search = {
                            year: 0,
                            value: searchParam,
                            model: "",
                            manufacture: ""
                        };

                        this.setState({
                            search: search,
                            isSearch: true,
                        }, async () => {
                            await this.handleEdit(search);
                        });
                        }
                }
            } else {
                console.log("ssddsd");
                const body = { value: searchParam, year: 0, model: "", manufacture: "" };
        
                this.setState({
                    search: body,
                    isSearch: true,
                }, async () => {
                    await this.handleEdit(body);
                });
            }
        }
            }

    fetchData = async (url, method = 'POST', body) => {
        const response = await fetch(url, {
            method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: body ? JSON.stringify(body) : null,
        });

        if (!response.ok) {
            return [];
        }

        this.setState({ isSearch: false })

        return await response.json();
    };

    getGroups = () => this.fetchData('/api/group');

    getProducts = (id) => {
        this.setState({ isSearch: false })
        const { currentPage, countOnPage } = this.state;
        const page = currentPage * countOnPage;
        const url = id ? `/api/product/catalog/${id}/${page}/${countOnPage}?token=${(new Token()).get()}` : `/api/productList/${page}/${countOnPage}?token=${(new Token()).get()}&all=${(new Discount()).get()}`;

        return this.fetchData(url);
    };

    getCountProducts = (id) => {
        const url = id ? `/api/product/catalogCount/${id}` : '/api/productListCount';
        return this.fetchData(url);
    };

    searchValue = async () => {
        const { search } = this.state;

        this.setState({
            products: [],
            searchLoader: false,
            countPages: 0,
            currentPage: 0,
        });

        const [products] = await Promise.all([
            await this.fetchData(`/api/search?token=${(new Token()).get()}`, 'POST', search)
        ]);

        this.setState({
            notFound: products.length == 0,
            products,
            isSearch: true,
            searchLoader: true,
        });
    };

    loadAllProducts = async () => {
        const { id } = this.props.match.params;

        this.scrollToTop();

        this.setState({
            currentPage: 0,
            isSearch: false,
        });

        const { countOnPage, currentPage } = this.state;

        try {
            const [groups, products, length] = await Promise.all([
                this.getGroups(),
                this.getProducts(id),
                this.getCountProducts(id),
            ]);

            const countPages = Math.ceil(length.count / countOnPage);

            this.setState({
                groups,
                products,
                countPages,
                loaded: true,
            });
        } catch (error) {
            this.setState({ error: "Ошибка загрузки каталога" });
        }
    };

    handlePageChange = (newPage) => {
        this.setState({ currentPage: newPage }, this.loadAllProducts);
    };

    handleSearch = async (search) => {
        this.setState({ search, notFound: false });
    };

    handleEdit = async (search) => {
        if (typeof search !== 'object' || search === null) {
            console.error('Invalid search parameter. Expected an object.');
            return;
        }

        this.setState({
            search: search
        }, async () => {
            if (search.value === '' && search.manufacture === '' && search.model === '' && search.year === 0) {
                this.loadAllProducts();
            } else {
                await this.searchValue();
            }
        });
    };

    handleChangeView = async () => {
        await this.loadAllProducts();
    };

    scrollToTop = () => {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    render() {
        const { loaded, isSearch, countPages, groups, products, currentPage, error, search, remainsMax, notFound } = this.state;

        return (
            <div className="product-category" style={{ top: 50, padding: 130, position: "relative", marginBottom: 75, minHeight: 600, boxSizing: "border-box" }}>
                {error && <div>{error}</div>}
                <div className="container">
                    <Breadcrumb values={new Map([["/", "Главная"], ["/#", "Каталог"]])} />
                    <div className="row">
                        <div className="col-sm-12">
                            {loaded && <h1 className="page-content__title section-title">Каталог</h1>}
                            <div className="row">
                                <aside className="col-sm-3 hidden-xs">
                                    {groups && groups.length > 0 &&
                                        <CategoryMenu groups={groups} count={countPages} />
                                    }
                                </aside>
                                <div ref={this.topRef} className="col-sm-9" style={{ paddingLeft: 10 }}>
                                    <Search
                                        visible={loaded}
                                        initialSearch={search}
                                        onEdit={this.handleEdit}
                                        onSearch={this.handleSearch}
                                        onDiscount={this.handleChangeView}
                                    />
                                    <div style={{ position: "absolute", top: 200, left: "50%" }} >
                                    </div>
                                    {products && products.length > 0 &&
                                        <div className="row">
                                            {products.map((product) => {
                                                if ((new Discount()).get() && product.hasDiscount) {
                                                    return <ProductDiscounted key={product.uuid} product={product} remainsMax={remainsMax} />;
                                                } else if (!(new Discount()).get()) {
                                                    return <ProductDiscounted key={product.uuid} product={product} remainsMax={remainsMax} />;
                                                } else {
                                                    return <ProductList key={product.uuid} product={product} remainsMax={remainsMax} />;
                                                }
                                            })}
                                        </div>
                                    }
                                    {notFound && products.length == 0 &&
                                        <div className="row">
                                            <div className="col-sm-12" style={{ textAlign: 'center' }}>
                                                <h1>
                                                    РЕЗУЛЬТАТЫ ПОИСКА
                                                </h1>
                                            </div>
                                            <div className="col-sm-12" style={{ textAlign: 'center' }}>
                                                <h1>
                                                    по вашему запросу ничего не найдено
                                                </h1>
                                            </div>
                                            <div className="col-sm-12" style={{ textAlign: 'center' }}>
                                                Чтобы найти деталь, введите в поиск ее номер или VIN.
                                            </div>
                                            <div className="col-sm-12" style={{ textAlign: 'center' }}>
                                                VIN можно найти в ПТС, а номер детали — в оригинальных каталогах или узнать у эксперта.
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {!isSearch && products && products.length > 0 &&
                                <Pagination onPageChange={this.handlePageChange} count={countPages} current={currentPage} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Catalog;
