import React, { Component } from 'react';

import HalykBank from "./HalykBank";
import KaspiQR from "./../payment/KaspiQR";

import Pay from './../enum/Pay';

import Token from "./../utils/Token";

class Payment extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cart: null,
            kaspiData: null,
        };
    }

    componentDidMount() {
        const { cart } = this.props.location.state;

        this.setState({ cart }, async () => {

            if (cart.pay === Pay.PAYMENTPICKUP) {
                try {
                    cart.payNumber = "";

                    const response = await fetch('/api/cart/save', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Token': (new Token()).get(),
                        },
                        body: JSON.stringify(cart)
                    });

                    if (response.ok) {
                        this.setState({ loaded: true });
                        this.props.history.push({ pathname: '/checkout' });
                    } else {
                        this.setState({ loaded: true });
                        this.props.history.push({
                            pathname: '/error',
                            state: { error: await response.text() },
                        });
                    }
                } catch (error) {
                    this.setState({ loaded: true });
                    this.props.history.push({
                        pathname: '/error',
                        state: { error: error.message },
                    });
                }
            }

            if (cart.pay === Pay.KASPI) {
                const response = await fetch('/api/payment/kaspi/create?amount=' + cart.amount, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Token': (new Token()).get(),
                    }
                });

                if (response.ok) {
                    const jsonData = await response.json(); 

                    this.setState({ kaspiData: jsonData})
                }

                this.setState({ loaded: true });
            }
        })
    }

    render() {
        const { cart, loaded, kaspiData } = this.state;

        return (
            <div className="page-content" style={{ padding: 130, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>
               <div className="container">                
                    <div style={{minHeight: 400}}>
                        
                        {cart && cart.pay == Pay.KASPI && kaspiData &&
                            <KaspiQR id={ kaspiData.QrToken } cart={ cart } data={ kaspiData }/>
                        }
                        { cart && cart.pay == Pay.CREDITCARD && 
                            <HalykBank cart={ cart } />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Payment;
