import React, { Component } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import 'swiper/css';

class Carousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            index: this.props.index,
            values: this.props.values,
        }
    }

    renderBrandItems(values) {
        return values.map((value, index) => (
            <SwiperSlide key={index}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "auto", margin: "auto", position: "relative" }}>
                    <img src={value.img} alt={value.name} style={this.styleImg} />
                </div>
            </SwiperSlide>
        ));
    }

    render() {
        const { index, values } = this.state;

        return (
            <>
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    initialSlide={index}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    style={{zIndex: 0}}
                >
                    {this.renderBrandItems(values)}

                </Swiper>
            </>);
    }
}

export default Carousel;
