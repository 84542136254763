import React, { Component } from 'react'

import Breadcrumb from '../../header/Breadcrumb'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import 'swiper/css';

class Review extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reviews: []
        };
    }

    async getReviews() {
        const response = await fetch('/api/review', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            return [];
        }

        return await response.json();
    }

    async componentDidMount() {
        const reviews = await this.getReviews();

        this.setState({
            reviews
        })
    }

    return() {
        const { reviews } = this.state;

        <div className="page-content" style={{ padding: 130, position: "relative", marginBottom: 75, boxSizing: "border-box" }}>
            <div className="container">

                <Breadcrumb values={new Map([
                    ["/", "Главная"],
                    ["/#", "Отзывы"],
                ])} />

                <h1 className="page-content__title section-title">
                    Отзывы
                </h1>

                <div className="page-content__description">

                    <div className="row">

                        <div className="col-sm-12">
                            <Swiper
                                spaceBetween={30}
                                centeredSlides={true}
                                initialSlide={0}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay]}
                                style={{ zIndex: 0 }}
                            >
                                {reviews.map(([key, value]) => (
                                    <SwiperSlide key={index}>
                                        <div className='row'>
                                            {value.user}
                                        </div>
                                        <div className='row'>
                                            {value.date}
                                        </div>
                                        <div className='row'>
                                            {value.text}
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Review;
