import React, { Component } from 'react';

class PartsCatalogs extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://gui.parts-catalogs.com/v2/parts-catalogs.js";
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    return (
      <div className="product-category" style={{ top: 50, padding: 130, position: "relative", marginBottom: 75, minHeight: 600, boxSizing: "border-box" }}>
        <div style={{ padding: 50}}>
          <div className="row">
            <div className="col-sm-12">
              <div id="parts-catalog" data-key="TWS-D117AD4A-6611-4CD7-9742-CFC8C1EEF593" data-back-url="https://fe.kz/catalog?search={article}" data-language="ru"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PartsCatalogs;
