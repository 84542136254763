import Format from "../../utils/Format";
import Token from "../../utils/Token";
import EventBus from "../../utils/EventBus";

const PostponedItem = ({ value, remainsMax, onUpdateCount }) => {
    const handleCountChange = async (event) => {
        let count = parseInt(event.target.value, 10);

        try {
            const response = await fetch('/api/postponed/set', {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Token': (new Token()).get(),
                },
                body: JSON.stringify({ uuid: value.product, count: count, amount: 0 })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            
            onUpdateCount();
            EventBus.emit('cartUpdated', true);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const handleRemoveChange = async () => {
        try {
            const response = await fetch(`/api/postponed/remove/${value.product}`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Token': (new Token()).get(),
                 }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            onUpdateCount();
            EventBus.emit('cartUpdated', true);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const handleToCart = async () => {
        try {
            const response = await fetch(`/api/cart/add`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Token': (new Token()).get(),
                 },
                 body: JSON.stringify({ uuid: value.product, count: value.count, amount: (value.count * value.product.price) })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            handleRemoveChange();

            onUpdateCount();
            EventBus.emit('cartUpdated', true);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    return (
        <tr>
            <td className="text-center" style={{width: "10%"}}>
                <i className="header-icon" onClick={handleToCart} style={{cursor: 'pointer'}}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 6H17C17 3.2 14.8 1 12 1C9.2 1 7 3.2 7 6H5C3.9 6 3 6.9 3 8V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V8C21 6.9 20.1 6 19 6ZM12 3C13.7 3 15 4.3 15 6H9C9 4.3 10.3 3 12 3ZM19 20H5V8H19V20ZM12 12C10.3 12 9 10.7 9 9H7C7 11.8 9.2 14 12 14C14.8 14 17 11.8 17 9H15C15 10.7 13.7 12 12 12Z" fill="#0f0f0f"></path>
                    </svg>
                </i>
            </td>
            <td className="text-center">{value.name}</td>
            <td className="text-center">{value.artikul}</td>
            <td className="text-right" style={{ minWidth: 110, maxWidth: 110 }}>
                <input
                    type="number"
                    value={value.count}
                    min={1}
                    max={!remainsMax ? value.remain : undefined}
                    onChange={handleCountChange}
                    style={{ textAlign: 'center', border: "none", outline: "none", minWidth: 110, maxWidth: 110 }}
                />
            </td>
            <td className="text-right"><Format value={(value.price)} /><span style={{marginLeft: 5}}>₸</span></td>
            <td className="text-right"><Format value={(value.price * value.count)} /><span style={{marginLeft: 5}}>₸</span></td>
            <td className="text-center">
                <img src="./../../images/icons/remove.png" style={{ cursor: 'pointer', width: 20, height: 20 }} onClick={handleRemoveChange} alt='delete' />
            </td>
        </tr>
    );
};

export default PostponedItem;
