import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce'; 
import ClearType from '../../enum/ClearType';
import Discount from "./../../utils/Discount";

const Search = ({ visible, initialSeach, onEdit, onSearch, onDiscount }) => {
    const [placeholder, setPlaceholder] = useState("Введите номер запчасти или наименование");
    const [activeButton, setActiveButton] = useState(1);
    const [manufactures, setManufactures] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState(initialSeach || {value: '', manufacture: '', model: '', year: 0});
    const [showTooltip, setShowTooltip] = useState(false); 

    useEffect(() => {
        const fetchManufactures = async () => {
            let fetchError = null;
            try {
                const response = await fetch('/api/manufactures', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();

                if (Array.isArray(data)) {
                    setManufactures(data);
                } else {
                    throw new Error('Data fetched is not an array');
                }
            } catch (error) {
                fetchError = error.message;
            } finally {
                if (fetchError) {
                    setError(fetchError);
                }
                setLoading(false);
            }
        };

        fetchManufactures();
    }, [error]);

    useEffect(() => {
        const delayedSearch = debounce(() => {
            onSearch(search);
        }, 300);

        delayedSearch();
        
        return () => {
            delayedSearch.cancel();
        };
    }, [search, onSearch]);

    const handleSearch = () => {
        onEdit(search);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleButtonClick = (i) => {
        if (i == 1) {
            const placeholders = {
                1: "Введите номер запчасти или наименование",
                2: "Введите модель или VIN",
                3: "Введите наименование категории",
            };
            setPlaceholder(placeholders[i]);
            setActiveButton(i);
        }
        if (i == 2) {
            window.location.href = "/parts_catalogs";
        }
    };

    const handleValueChange = (event) => {
        setSearch(prevState => ({
            ...prevState,
            value: event.target.value
        }));
    };

    const toopTip = (value) => {
        setShowTooltip(value);

        if (value) {
            setTimeout(() => {
                setShowTooltip(false);
            }, 3000); 
        }
    }

    const handleManufactureChange = (event) => {
        setSearch(prevState => ({
            ...prevState,
            manufacture: event.target.value,
            year: 0,
            model: "",
        }));
        toopTip(true);
    };

    const handleModelChange = (event) => {
        setSearch(prevState => ({
            ...prevState,
            model: event.target.value,
            year: 0,
        }));
        toopTip(true);
    };

    const handleYearChange = (event) => {
        setSearch(prevState => ({
            ...prevState,
            year: parseInt(event.target.value, 10),
        }));
        toopTip(true);
    };

    const handleChangeView = (event) => {
        const isChecked = event.target.checked;
        (new Discount()).update(isChecked);
        onDiscount();
    };

    const handleCleare = (value) => {
        let updatedSearch = {};
        
        if (value === ClearType.MANUFACTURE) {
            updatedSearch = { ...search, year: 0, model: "", manufacture: "" };
        }
        if (value === ClearType.MODEL) {
            updatedSearch = { ...search, year: 0, model: "" };
        }
        if (value === ClearType.YEAR) {
            updatedSearch = { ...search, year: 0 };
        }

        if (value === ClearType.ALL) {
            updatedSearch = { year: 0, model: "", value: "", manufacture: "" };
        }

        setSearch(updatedSearch);
        onEdit(updatedSearch);
        toopTip(true);
    };

    const getModelsForSelectedManufacture = () => {
        const selectedManufacture = manufactures.find(m => m.name === search.manufacture);
        return selectedManufacture ? selectedManufacture.models : [];
    };

    const getYearsForSelectedModel = () => {
        const selectedManufacture = manufactures.find(m => m.name === search.manufacture);
        if (selectedManufacture) {
            const selectedModel = selectedManufacture.models.find(m => m.name === search.model);
            return selectedModel ? selectedModel.years : [];
        }
        return [];
    };

    if (loading) {
        return <div></div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="search-bar__block" style={{ display: visible ? "block" : "none" }}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="search-bar__btn-wrapper">
                        <button onClick={() => handleButtonClick(1)} className={`search-btn ${activeButton === 1 ? 'search-btn__active' : ''}`}>По номеру запчасти</button>
                        <button onClick={() => handleButtonClick(2)} className={`search-btn ${activeButton === 2 ? 'search-btn__active' : ''}`} style={{cursor: 'pointer'}}>По модели / VIN коду</button>
                    </div>
                    
                    {showTooltip && (
                        <span style={{
                            top: 20,
                            width: "220px",
                            backgroundColor: "black", 
                            color: "white",
                            textAlign: "center",
                            borderRadius: "5px",
                            padding: "5px",
                            position: "absolute",
                            Zindex: -1,
                            transition: "opacity 350ms, transform 350ms"
                        }}>
                            Нажмите&nbsp;
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 3C11.2239 3 12.8772 3.68482 14.0962 4.90381C15.3152 6.12279 16 7.77609 16 9.5C16 11.11 15.41 12.59 14.44 13.73L14.71 14H15.5L20.5 19L19 20.5L14 15.5V14.71L13.73 14.44C12.59 15.41 11.11 16 9.5 16C7.77609 16 6.12279 15.3152 4.90381 14.0962C3.68482 12.8772 3 11.2239 3 9.5C3 7.77609 3.68482 6.12279 4.90381 4.90381C6.12279 3.68482 7.77609 3 9.5 3ZM9.5 5C7 5 5 7 5 9.5C5 12 7 14 9.5 14C12 14 14 12 14 9.5C14 7 12 5 9.5 5Z" fill="#545D77"></path>
                            </svg>&nbsp;для&nbsp;поиска
                        </span>
                    )}

                    <div className="input-group search-bar">
                        <button 
                            className="input-group-text search-bar__button" 
                            onClick={handleSearch} 
                            style={{ cursor: "pointer" }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 3C11.2239 3 12.8772 3.68482 14.0962 4.90381C15.3152 6.12279 16 7.77609 16 9.5C16 11.11 15.41 12.59 14.44 13.73L14.71 14H15.5L20.5 19L19 20.5L14 15.5V14.71L13.73 14.44C12.59 15.41 11.11 16 9.5 16C7.77609 16 6.12279 15.3152 4.90381 14.0962C3.68482 12.8772 3 11.2239 3 9.5C3 7.77609 3.68482 6.12279 4.90381 4.90381C6.12279 3.68482 7.77609 3 9.5 3ZM9.5 5C7 5 5 7 5 9.5C5 12 7 14 9.5 14C12 14 14 12 14 9.5C14 7 12 5 9.5 5Z" fill="#545D77"></path>
                            </svg>
                        </button>

                        <input 
                            type="text" 
                            className="form-control search-bar__input" 
                            value={search.value} 
                            placeholder={placeholder} 
                            onKeyDown={handleKeyDown} 
                            onChange={handleValueChange} 
                        />
                        <button style={{width: 50, border: "1px solid", background: "#FFF"}} title="Очистить" onClick={() => handleCleare(ClearType.ALL)}>
                            <img src="./../../images/icons/clear.png" style={{ cursor: 'pointer', width: 20, height: 20 }} />
                        </button>

                    </div>

                    <div className="input-group search-bar" style={{ top: 5 }}>
                        <select className="form-control search-bar__select" value={search.manufacture} onChange={handleManufactureChange}>
                            <option value="">Марка авто</option>
                            {manufactures.map((item, index) => (
                                <option key={index} value={item.name}>{item.name}</option>
                            ))}
                        </select>
                        <button style={{width: 50, border: "1px solid", background: "#FFF"}} title="Очистить" onClick={() => handleCleare(ClearType.MANUFACTURE)}>
                            <img src="./../../images/icons/clear.png" style={{ cursor: 'pointer', width: 20, height: 20 }} />
                        </button>
                        &nbsp;
                        <select className="form-control search-bar__select" value={search.model} onChange={handleModelChange}>
                            <option value="">Модель</option>
                            {getModelsForSelectedManufacture().map((item, index) => (
                                <option key={index} value={item.name}>{item.name}</option>
                            ))}
                        </select>
                        <button style={{width: 50, border: "1px solid", background: "#FFF"}} title="Очистить" onClick={() => handleCleare(ClearType.MODEL)}>
                            <img src="./../../images/icons/clear.png" style={{ cursor: 'pointer', width: 20, height: 20 }} />
                        </button>
                        &nbsp;
                        <select className="form-control search-bar__select" value={search.year} onChange={handleYearChange}>
                            <option value="">Год выпуска</option>
                            {getYearsForSelectedModel().map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                        <button style={{width: 50, border: "1px solid", background: "#FFF"}} title="Очистить" onClick={() => handleCleare(ClearType.YEAR)}>
                            <img src="./../../images/icons/clear.png" style={{ cursor: 'pointer', width: 20, height: 20 }} />
                        </button>
                    </div>

                    <div className="input-group search-bar" style={{ top: 15 }}>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" checked={(new Discount()).get()} onChange={handleChangeView}/>
                            <label className="form-check-label">Только товары со скидкой</label>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

Search.propTypes = {
    visible: PropTypes.bool.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDiscount: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
};

export default Search;
