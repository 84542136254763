import Format from "./../../utils/Format";
import Token from "./../../utils/Token";
import EventBus from "./../../utils/EventBus";

const CartItem = ({ value, remainsMax, onUpdateCount }) => {
    const handleCountChange = async (event) => {
        let count = parseInt(event.target.value, 10);

        try {
            const response = await fetch('/api/cart/set', {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Token': (new Token()).get(),
                },
                body: JSON.stringify({ uuid: value.product, count: count, amount: 0 })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            
            onUpdateCount();
            EventBus.emit('cartUpdated', true);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const handleRemoveChange = async () => {
        try {
            const response = await fetch(`/api/cart/remove/${value.product}`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Token': (new Token()).get(),
                 }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            onUpdateCount();
            EventBus.emit('cartUpdated', true);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const handlePostponed = async () => {
        try {
            const response = await fetch(`/api/postponed/add`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Token': (new Token()).get(),
                 },
                 body: JSON.stringify({ uuid: value.product, count: value.count, amount: (value.count * value.product.price) })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            handleRemoveChange();
            
            onUpdateCount();

            EventBus.emit('cartUpdated', true);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    return (
        <tr>
            <td className="text-center" style={{width: "10%"}}>
                <i className="header-icon" onClick={handlePostponed} style={{cursor: 'pointer'}}>
                    <img src="/images/icons/time.svg" alt={"Отложить"} title={"Отложить"}/>
                </i>
            </td>
            <td className="text-center" style={{width: "10%"}}>
                <img src={`/api/image/1/${value.product}`} alt={value.name} title={value.name} style={{width: "75%", height: "75%"}}/>
            </td>
            <td className="text-center">{value.name}</td>
            <td className="text-center">{value.artikul}</td>
            <td className="text-right" style={{ minWidth: 110, maxWidth: 110 }}>
                <input
                    type="number"
                    value={value.count}
                    min={1}
                    max={!remainsMax ? value.remain : undefined}
                    onChange={handleCountChange}
                    style={{ textAlign: 'center', border: "none", outline: "none", minWidth: 110, maxWidth: 110 }}
                />
            </td>
            <td className="text-right"><Format value={(value.price)} /><span style={{marginLeft: 5}}>₸</span></td>
            <td className="text-right"><Format value={(value.price * value.count)} /><span style={{marginLeft: 5}}>₸</span></td>
            <td className="text-center">
                <img src="./../../images/icons/remove.png" style={{ cursor: 'pointer', width: 20, height: 20 }} onClick={handleRemoveChange} alt='delete' />
            </td>
        </tr>
    );
};

export default CartItem;
