import React, { useState, useEffect } from 'react';

const MainToastNotification = ({ text, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setIsVisible(true);

    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 30000);

    return () => clearTimeout(timer);
  }, [text, onClose]);

  return (
    isVisible && (
      <div className="toast-container">
        <div className="toast toast-primary">
          <div className="row">
            <div className="col-sm-12">
              <div className="toast-body container">
                <span dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            </div>
            <div className="col-sm-12" style={{textAlign: 'center'}}>
              <button className="toast-close" style={{ border: 'solid 1', top: 20 }} onClick={() => { setIsVisible(false); onClose(); }}>
                ЗАКРЫТЬ
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default MainToastNotification;
