import React, { Component } from 'react';

import HomeBanner from "./HomeBanner";
import SelectionTop from './SelectionTop';
import SectionDiscounted from './SectionDiscounted';
import SectionAdvantages from "./SectionAdvantages";
import SectionBrends from "./SectionBrends";
import SectionReviews from "./SectionReviews";
import SectionNews from "./SectionNews";
import SelectionCars from "./SelectionCars";
import SectionContacts from "./SectionContacts";

import User from './../../utils/User';
import UserType from './../../enum/UserType';

import Notification from './../../utils/Notification';
import MainToastNotification from '../../utils/MainToastNotification';

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      message: '',
      messageId: '',
      isService: false,
      showToast: false,
    };
  }

  async getMessage() {
    try {
        const response = await fetch('/api/message', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        if (response.ok){
            if (result.message !== '') {
              this.setState({ showToast: true, message: result.message });
            }
        }
    } catch (error) { }
  }

  async componentDidMount() {
    const { isService } = this.props; 
    
    this.setState({ isService: isService });

    this.getMessage();
  }

  setHideToast() {
    this.setState({ showToast: false });
  }

  render() {
    const { isService, showToast, message } = this.state;

    if (isService) {
      return (<></>)
    }

    return (
      <>
        <HomeBanner />
        <SelectionCars />
        <SelectionTop />
        {new User().getType() != UserType.WHOLESALE && (
          <SectionDiscounted />
        )}
        <SectionAdvantages />
        <SectionBrends />
        <SectionReviews />
        <SectionNews />
        <SectionContacts />
        {showToast && <MainToastNotification text={message} onClose={() => this.setHideToast()} />}
      </>
    )
  }
}

export default Index;
