import UserType from './../enum/UserType';

class User {
    constructor() {
        this.name = "";
        this.type = UserType.UNDEFINED;
    }

    get() {
        return localStorage.getItem('name');
    }

    getShortName() {
        try {
            const name = localStorage.getItem('name');
            if (!name) return ""; 
    
            const parts = name.trim().split(/\s+/);
            if (parts.length < 2) return name; 
    
            const firstName = parts[0];
            const initials = parts.slice(1).map(word => word[0] + '.').join(' ');
    
            return `${firstName} ${initials}`;
        } catch {
            return "";
        }
    }

    update(name) {
        localStorage.setItem('name', name);
    }

    getType() {
        return localStorage.getItem('type');
    }

    updateType(type) {
        localStorage.setItem('type', type);
    }

    delete() {
        localStorage.removeItem('type');
        localStorage.removeItem('name');
    }
}

export default User;
