import React, { Component } from 'react';

import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import FormattedDate from "./../../utils/FormattedDate";

import 'swiper/css';

class SectionReviews extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reviews: []
        };
    }

    async getReviews() {
        const response = await fetch('/api/reviews', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            return [];
        }

        return await response.json();
    }

    async componentDidMount() {
        const reviews = await this.getReviews();

        this.setState({
            reviews
        });
    }

    render() {
        const { reviews } = this.state;

        return (
            <div className="section section-reviews">
                <div className="container">

                    <div className="section-title">
                        <center>Отзывы</center>
                    </div>

                    <div className="row">
                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            initialSlide={0}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                            style={{ zIndex: 0 }}
                        >
                            {reviews.map((review, index) => (
                                JSON.parse(review.text).review.length > 0 && (
                                    <SwiperSlide key={index}>
                                        <div className="row">
                                            <div className="col-1">
                                                <img src="./images/user.png" style={{ width: 50, height: 50 }} alt="User" />
                                            </div>
                                            <div className="col-11">
                                                <div className="row">
                                                    <div className="col-9">
                                                        <b>{review.user}</b>
                                                    </div>
                                                    <div className="col-3">
                                                        <FormattedDate isoString={review.date} />
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="row">
                                                    <div className="col-12">
                                                        {JSON.parse(review.text).review}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            ))}
                        </Swiper>
                    </div>

                </div>

                <div className="section-line-bg">
                    <svg width="2000" height="40" viewBox="0 0 2000 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1429.1 40.0002L1999.5 40.0042L1999.99 0L0.0101283 -6.32303e-07L-3.1476e-06 4.00003L1036.38 4.00017L1102.01 40.0002L1429.1 40.0002Z" fill="#DFDFDF"></path>
                    </svg>
                </div>

                <div className="section-line">
                    <svg width="2000" height="38" viewBox="0 0 2000 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 1H1019L1087 37H1999.5" stroke="#0575C0" strokeWidth="2"></path>
                    </svg>
                </div>

            </div>
        );
    }
}

export default SectionReviews;
