import React, { Component } from 'react';

class Pagination extends Component {
    constructor(props) {
        super(props);

        this.state = {
            count: this.props.count,
            current: this.props.current,
        };

        this.up = this.up.bind(this);
        this.down = this.down.bind(this);
        this.set = this.set.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.count !== this.props.count) {
            this.setState({
                count: this.props.count,
            });
        }
    }

    up() {
        let current = this.state.current;
        if ((current + 1) >= (this.state.count )) {
            current = this.state.count;
        } else {
            current += 1;
        }
        this.setState({
            current: current
        });
        this.scrollToTop();
        this.props.onPageChange(current)
    }

    down() {
        let current = this.state.current;
        if ((current - 1) <= 0) {
            current = 0;
        } else {
            current -= 1;
        }
        this.setState({
            current: current
        });
        this.scrollToTop();
        this.props.onPageChange(current)
    }

    set(i) {
        this.setState({
            current: i
        });
        this.scrollToTop();
        this.props.onPageChange(i)
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    renderPage(count, current) {
        if (current < 2) {
            return (
                <>
                    {count >= 0 &&
                        <li className={ current === 0 ? 'page-item active' : 'page-item' }>
                            <button className="page-link" onClick={() => this.set(0)} style={{boxShadow: "none"}}>
                                <span className="page-link">{1}</span>
                            </button>
                        </li>
                    }
                    {count >= 1 &&
                        <li className={ current === 1 ? 'page-item active' : 'page-item' }>
                            <button className="page-link" onClick={() => this.set(1)} style={{boxShadow: "none"}}>
                                <span className="page-link">{2}</span>
                            </button>
                        </li>
                    }
                    {count >= 2 &&
                        <li className={current === 2 ? 'page-item active' : 'page-item'}>
                            <button className="page-link" onClick={() => this.set(2)} style={{boxShadow: "none"}}>
                                <span className="page-link">{3}</span>
                            </button>
                        </li>
                    }
                    {count > 3 &&
                        <li className="page-item">
                            <span className="page-link">...</span>
                        </li>
                    }
                </>
            )
        }

        if (count > 2) {
            if ((current >= 2) && (current < count)) {
                return (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => this.set(0)} style={{boxShadow: "none"}}>
                                <span className="page-link">{1}</span>
                            </button>
                        </li>
                        <li className="page-item">
                            <span className="page-link">...</span>
                        </li>
                        <li className="page-item active">
                            <button className="page-link" onClick={() => this.set(current)} style={{boxShadow: "none"}}>
                                <span className="page-link">{current + 1}</span>
                            </button>
                        </li>
                        <li className="page-item">
                            <span className="page-link">...</span>
                        </li>
                        <li className="page-item">
                            <button className="page-link" onClick={() => this.set(count)} style={{boxShadow: "none"}}>
                                <span className="page-link">{count + 1}</span>
                            </button>
                        </li>
                    </>
                );
            }
        }
    }
    
    render() {
        const { count, current } = this.state;

        let countUse = count - 1;

        return (
            <div className="page-content__pagination">
                {countUse > 1 &&
                    <ul className="pagination">
                        <div style={{ display: current >= 1 ? 'block' : 'none' }}>
                            <li className="page-item page-item__auto">
                                <button className="page-link" onClick={this.down} style={{boxShadow: "none"}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 4L8 12L16 20" stroke="#0575C0" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                    Предыдущая
                                </button>
                            </li>
                        </div>

                        { this.renderPage(countUse, current) }

                        <div style={{ display: current < countUse ? 'block' : 'none' }}>
                            <li className="page-item page-item__auto">
                                <button className="page-link" onClick={this.up} style={{boxShadow: "none"}}>
                                    Следующая
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 4L16 12L8 20" stroke="#0575C0" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </button>
                            </li>
                        </div>

                    </ul>
                }
            </div>
        );
    }
}

export default Pagination;
