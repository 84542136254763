import React, { Component } from 'react';
import Modal from 'react-modal';
import { formatAmount } from "./../utils/Format";
import { withRouter } from 'react-router-dom';
import Token from "./../utils/Token"; 

class KaspiQR extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            data: this.props.data,
            cart: this.props.cart,
            isPaid: false,
            isError: false,
            isWating: false,
            imageUrl: null,
            modalIsOpen: true,
            redirectUrl: null,
        };

        this.redirect = this.redirect.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.checkPaymentStatus = this.checkPaymentStatus.bind(this);
    }

    async getQrCode() {
        const { id } = this.state;

        try {
            const response = await fetch('/api/payment/kaspi/qrcode?content=' + id, {
                method: 'GET',
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const arrayBuffer = await response.arrayBuffer();
            const byteArray = new Uint8Array(arrayBuffer);
            const blob = new Blob([byteArray], { type: 'image/png' });
            const imageUrl = URL.createObjectURL(blob);
    
            this.setState({ imageUrl });

            this.paymentStatusInterval = setInterval(this.checkPaymentStatus, 3000);
        } catch (error) {
            console.error('Failed to fetch QR code image:', error);
            this.setState({ isError: true });
        }
    }

    async checkPaymentStatus() {
        const { data, cart } = this.state;

        if (data != null) {
            try {
                const token = (new Token()).get()

                const response = await fetch(`/api/payment/kaspi/status?qrPaymentId=${data.QrPaymentId}&token=${token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Token': token,
                    },
                    body: JSON.stringify(cart)
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                const paymentData = await response.json(); 

                if (paymentData.Status === 'Wait') {
                    this.setState({ isWating: true, isError: false, isPaid: false });

                    this.forceUpdate();
                }
                
                if (paymentData.Status === 'Error') {
                    if (this.paymentStatusInterval) {
                        clearInterval(this.paymentStatusInterval);
                    }

                    this.setState({ isError: true, isPaid: false, isWating: false, redirectUrl: "/error" });
                    
                    this.forceUpdate();

                    this.redirectStatusInterval = setInterval(this.redirect, 3000);
                }

                if (paymentData.Status === 'Processed') {
                    if (this.paymentStatusInterval) {
                        clearInterval(this.paymentStatusInterval);
                    }
                    
                    const { cart } = this.state;

                    cart.payNumber = data.QrPaymentId;

                    const responseCheckout = await fetch('/api/cart/checkout', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Token': (new Token()).get(),
                        },
                        body: JSON.stringify(cart)
                    });

                    if (responseCheckout.ok) {
                        this.setState({ isPaid: true, isWating: false, isError: false, redirectUrl: "/checkout" });

                        this.redirectStatusInterval = setInterval(this.redirect, 3000);
                    
                        this.forceUpdate();
                    }
                }

            } catch (error) {
                console.error('Failed to check payment status:', error);
            }
        }
    }
    
    componentDidMount() {
        this.getQrCode();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState({
                id: this.props.id,
            });
        }

        if (prevProps.cart !== this.props.cart) {
            this.setState({
                cart: this.props.cart,
            });
        }

        if (prevProps.data !== this.props.data) {
            this.setState({
                data: this.props.data,
            });
        }
    }

    componentWillUnmount() {
        if (this.paymentStatusInterval) {
            clearInterval(this.paymentStatusInterval);
        }

        if (this.redirectStatusInterval) {
            clearInterval(this.redirectStatusInterval);
        }
    }

    redirect() {
        const { redirectUrl } = this.state;

        if (redirectUrl != null) {
            clearInterval(this.redirectStatusInterval);

            this.props.history.push(redirectUrl); 
        }
    }

    closeModal() {
        this.setState({ modalIsOpen: false });

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const { imageUrl, isError, isWating, isPaid,  cart, modalIsOpen } = this.state;

        return (
            <Modal
                isOpen={ modalIsOpen }
                onRequestClose={ this.closeModal }
                style={ this.customStyles }
                shouldCloseOnOverlayClick={ false }
            >
                <div className="modal-content" style={{ border: 0 }}>
                    {isError && (
                        <div style={{ display: "block", marginTop: 150 }}>
                            <div className="kaspi-content" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <img style={{ width: 200, margin: "0 auto", marginTop: "80" }} src="./images/kaspi/error.png" alt="Error" />
                                <h5 className="mt-5" style={{ textAlign: "center" }}>
                                    <b>Ошибка</b>
                                </h5>
                                <h5 className="mt-3" style={{ textAlign: "center" }}>Попробуйте еще раз</h5>
                            </div>
                        </div>
                    )}

                    {isWating && (
                        <div style={{ display: "block", marginTop: 150 }}>
                            <div className="kaspi-content" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <img style={{ width: 200, margin: "0 auto", marginTop: "80" }} src="./images/kaspi/spinner.gif" alt="Wating" />
                                <h5 className="mt-5" style={{ textAlign: "center" }}>
                                    <b>Подтвердите оплату</b>
                                </h5>
                            </div>
                        </div>
                    )}

                    {isPaid && (
                        <div style={{ display: "block", marginTop: 150 }}>
                            <div className="kaspi-content" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <img style={{ width: 200, margin: "0 auto", marginTop: "80" }} src="./images/kaspi/ok.png" alt="OK" />
                                <h5 className="mt-5" style={{ textAlign: "center" }}>
                                    <b>Оплата принята</b>
                                </h5>
                            </div>
                        </div>                    
                    )}
                    
                    {!isError && !isWating && !isPaid && (
                        <div style={{ display: "block" }}>
                            <div className="kaspi-content" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <img className="mt-4" src="./images/kaspi/scanandpay.png" alt="Scan and Pay" />
                                <div className="d-flex">
                                    <h1 className="mt-3">
                                        <b>{formatAmount(cart.amount)}₸</b>
                                    </h1>
                                </div>
                                <div className="mt-3" style={{ position: "relative" }}>
                                    {imageUrl && <img src={imageUrl} alt="QR Code" />}
                                </div>
                                <h5 className="mt-3" style={{ textAlign: "center" }}>Способы оплаты</h5>
                                <img style={{ width: 150 }} className="mt-2" src="./images/kaspi/Payment.png" alt="Payment Methods" />
                            </div>
                        </div>
                    )}

                </div>
            </Modal>
        );
    }

    customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: 320,
            height: 650,
            maxWidth: 320,
            marginTop: 25,
            transform: 'translate(-50%, -50%)',
        },
    };
}

export default withRouter(KaspiQR);